<template>
  <section class="config-selector main-body">
    <self-help location="sponsors" />

    <h1 class="heading">Sponsors Selector</h1>

    <ul class="config-selector__list" v-if="sectionSponsorsConfig">
      <li class="config-selector__item" v-if="sectionSponsorsConfig.default.footerSponsors">
        <div class="config-selector__inner">
          <h2 class="config-selector__subheading subheading">Footer Sponsor Block</h2>
        </div>
        <v-btn :to="'/sponsors/footer'">Edit</v-btn>
      </li>

      <li class="config-selector__item" v-if="sectionSponsorsConfig.default.loadingScreen_App">
        <div class="config-selector__inner">
          <h2 class="config-selector__subheading subheading">App Loading Screen</h2>
        </div>
        <v-btn :to="'/sponsors/loading-screen'">Edit</v-btn>
      </li>

      <li class="config-selector__item" v-if="sectionSponsorsConfig.default.homepageNews_App || sectionSponsorsConfig.default.homepageNews_Web">
        <div class="config-selector__inner">
          <h2 class="config-selector__subheading subheading">Homepage News</h2>
        </div>
        <v-btn :to="'/sponsors/homepage-news'">Edit</v-btn>
      </li>

      <li class="config-selector__item" v-if="sectionSponsorsConfig.default.newsListing_App || sectionSponsorsConfig.default.newsListing_Web">
        <div class="config-selector__inner">
          <h2 class="config-selector__subheading subheading">News Listing Page</h2>
        </div>
        <v-btn :to="'/sponsors/news-listing'">Edit</v-btn>
      </li>

      <li class="config-selector__item" v-if="sectionSponsorsConfig.default.matchCentre_FirstTeam_App || sectionSponsorsConfig.default.matchCentre_FirstTeam_Web">
        <div class="config-selector__inner">
          <h2 class="config-selector__subheading subheading">Match Centre - First Team</h2>
        </div>
        <v-btn :to="'/sponsors/match-centre-first-team'">Edit</v-btn>
      </li>

      <li class="config-selector__item" v-if="sectionSponsorsConfig.default.fixturesPage_FirstTeam_App || sectionSponsorsConfig.default.fixturesPage_FirstTeam_Web">
        <div class="config-selector__inner">
          <h2 class="config-selector__subheading subheading">Fixtures Page - First Team</h2>
        </div>
        <v-btn :to="'/sponsors/fixtures-page-first-team'">Edit</v-btn>
      </li>

      <li class="config-selector__item" v-if="sectionSponsorsConfig.default.playerOfTheMatch_App || sectionSponsorsConfig.default.playerOfTheMatch_Web">
        <div class="config-selector__inner">
          <h2 class="config-selector__subheading subheading">Player of the Match</h2>
        </div>
        <v-btn :to="'/sponsors/player-of-the-match'">Edit</v-btn>
      </li>

      <li class="config-selector__item" v-if="sectionSponsorsConfig.default.scorePredictor_App || sectionSponsorsConfig.default.scorePredictor_Web">
        <div class="config-selector__inner">
          <h2 class="config-selector__subheading subheading">Score Predictor</h2>
        </div>
        <v-btn :to="'/sponsors/score-predictor'">Edit</v-btn>
      </li>
    </ul>
  </section>
</template>

<script>
import SelfHelp from "@/components/Library/SelfHelp"

export default {
  name: "SponsorsSelector",

  components: {
    SelfHelp
  },

  data: () => ({
    sectionSponsorsConfig: null,
  }),

  created() {
    this.sectionSponsorsConfig = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/sectionSponsors.js")
  }
}
</script>

<style scoped lang="scss">
  .config-selector {
    &__list {
      padding: 0;
      margin: 20px 0 0;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      margin: 0 0 16px;
      border: 1px solid #67247c;
      border-radius: 6px;
    }

    &__subheading {
      margin: 0;
      padding: 5px 0 0;
    }
  }
</style>
